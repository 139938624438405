import className from "classnames"
import { ButtonSolid } from "~/shared/ui/Buttons"
import { Can, Role } from "~/shared/ui/Can"
import { ComponentDiff } from "./ComponentDiff"
import { ComponentTime } from "./ComponentTime"
import { ComponentVersion } from "./ComponentVersion"
import type { Context } from "~/models/Context"
import type { Deployment, DeploymentStatus } from "~/models/Component"
import { Toast } from "~/shared/ui/Toast"

const ButtonDeployContent = (props: {
  isCurrent: boolean
  status: DeploymentStatus
}) => {
  const { isCurrent, status } = props
  if (status === "PENDING") return "Loading..."
  if (status === "ERROR") return "Error :("
  if (status === "SUCCESS") return "Deployed :)"
  return isCurrent ? "Redeploy" : "Deploy"
}

const DeployToast = (props: { id: string; status: DeploymentStatus }) => {
  if (props.status === "SUCCESS") {
    return <Toast id={props.id} message="Deployed" variant="success" />
  }

  if (props.status === "ERROR") {
    return (
      <Toast id={props.id} message="Something went wrong" variant="error" />
    )
  }

  return null
}

type Props = {
  activeVersion: string | undefined
  collapseActive: boolean
  context: Context
  deployment: Deployment
  deploymentStatuses: { [key: string]: DeploymentStatus }
  isCurrent: boolean
  isNew: boolean
  locked: boolean
  onDeployComponent: (deploymentId: string) => void
}

export const ComponentBuild = (props: Props) => {
  const {
    activeVersion,
    collapseActive,
    deployment,
    isCurrent,
    isNew,
    locked,
    deploymentStatuses,
  } = props

  const status = deploymentStatuses[deployment.Id] || "IDLE"

  const handleDeploy = () => {
    if (deployment) {
      props.onDeployComponent(deployment.Id)
    }
  }

  const rootClasses = className({
    "t_component_build py-4 px-4 border-b border-slate-200": true,
    "bg-slate-100": !isCurrent && !isNew,
    "bg-green-100": isCurrent,
    "bg-white": isNew,
  })

  const isCollapsed = collapseActive && isCurrent

  return (
    <div className={rootClasses}>
      <div className="grid grid-cols-5">
        <div className="t_column_info col-span-3">
          <ComponentTime
            className="text-slate-900 font-semibold"
            time={deployment.ModifiedTS}
            isNew={isNew}
          />
          {isCollapsed ? null : (
            <div>by {deployment.CommitAuthor || "Unknown"}</div>
          )}
          <ComponentVersion type="GitHash" version={deployment.Version} />
          {isNew && (
            <ComponentDiff
              activeVersion={activeVersion || undefined}
              targetVersion={deployment.Version}
              repoName={deployment.PipelineSlug}
            />
          )}
          {isCollapsed ? null : (
            <p className="text-slate-400 pt-2">{deployment.ChangeLog}</p>
          )}
        </div>

        <div className="t_column_current">
          {isCurrent && <span>Current</span>}
          {isNew && <span className="text-green-600">New</span>}
        </div>

        <DeployToast id={deployment.Id} status={status} />

        {!locked && !isCollapsed && (
          <div className="t_column_deploy">
            <Can
              currentUser={props.context.currentUser}
              requiredRole={Role.deployer}
              yes={
                <ButtonSolid
                  onClick={handleDeploy}
                  disabled={status === "PENDING"}
                >
                  <ButtonDeployContent isCurrent={isCurrent} status={status} />
                </ButtonSolid>
              }
              no={
                <ButtonSolid disabled>
                  <ButtonDeployContent isCurrent={isCurrent} status={status} />
                </ButtonSolid>
              }
            />
          </div>
        )}
      </div>
    </div>
  )
}
